<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Directory | Art Centers  </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'academies',params:{ Maincatg:'All'}}">Art Centers</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8" v-for="Data in RespArray" v-bind:key="Data.id">
                <div>
                  <b-row class="page-title-text" >
                    <h5>{{Data.academy_name}}</h5>
                  </b-row>    
                </div>
                <div>
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-12 Thira profile-card-5">
                        <div class="card-img-block">
                          <img v-bind:src="Data.academy_photo" alt="Image">
                        </div>
                        <p class="pre-formatted" v-if='Data.academy_description !== ("" || null)' ><span v-html="Data.academy_description"></span></p>    
                    </div>
                  </b-row>  
                </div>  
                <!-- Contact us section starts here  -->
                <div>
                <div class="container">
                    <div class="row">

                    <div class="col-lg-6 col-md-6 contact-card">
                        <h5>Contact Us</h5>
                        <p>
                        {{Data.academy_contact_name}} <br>
                        {{Data.academy_address}}<br>
                        {{Data.prof_city}}, {{Data.prof_state}}, {{Data.prof_country}} <br><br>
                        <strong v-if='Data.academy_cntct1 !== ("" || null)'>Primary No:</strong> {{Data.academy_cntct1}}<br>
                        <strong v-if='Data.academy_cntct2 !== ("" || null)'>Secondary No:</strong> {{Data.academy_cntct2}}<br><br>
                        <strong v-if='Data.academy_email !== ("" || null)'>Email:</strong> {{Data.academy_email}}<br>
                        <strong v-if='Data.academy_website !== ("" || null)'>Website:</strong> <a v-bind:href="Data.academy_website" target="_blank">{{Data.academy_website}}</a><br>
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 contact-card">
                        <h5>Social Profiles</h5>
                        <div class="social-links mt-3">
                        <a class="facebook" v-if='Data.academy_facebook !== ("" || null)' v-bind:href="Data.academy_facebook" target="_blank"><i class="bx bxl-facebook"></i></a>
                        <a class="instagram" v-if='Data.academy_instagram !== ("" || null)' v-bind:href="Data.academy_instagram" target="_blank"><i class="bx bxl-instagram"></i></a>
                        <a class="twitter" v-if='Data.academy_twitter !== ("" || null)' v-bind:href="Data.academy_twitter" target="_blank"><i class="bx bxl-twitter"></i></a>
                        <a class="facebook" v-if='Data.academy_youtube !== ("" || null)' v-bind:href="Data.academy_youtube" target="_blank"><i class="bx bxl-youtube"></i></a>
                        </div>
                    </div>

                    </div>
                </div>
                </div>   
                <!-- Description Tabs starts here  -->
                <div class="contact-card tab-desc-formatted">
                <b-tabs content-class="mt-3">
                    <b-tab title="Courses" active><p class="" v-if='Data.academy_courses !== ("" || null)' ><span v-html="Data.academy_courses"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Programs"><p class="" v-if='Data.academy_programs !== ("" || null)' ><span v-html="Data.academy_programs"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Activities"><p class="" v-if='Data.academy_activities !== ("" || null)' ><span v-html="Data.academy_activities"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Achievements"><p class="" v-if='Data.academy_achievement !== ("" || null)' ><span v-html="Data.academy_achievement"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                </b-tabs>
                </div>  
                <div class="contact-card">
                  <GoogleAdsense slot="2761789315"/>
                </div>                  
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar" v-if="ShowSideCatg">

                <!-- sidebar recent posts-->
                <SideRecentAcademies :key="componentKey"/>              
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer v-if="ShowSideCatg"/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"ProfessionalAcademies",
    //insert the following code for vue-meta to work
    metaInfo() {
        return {
            title: `${this.MetaName} - ${this.MetaTagline} - Academy Portfolio :: Thiraseela.com`,
            meta: [
                { name: 'description', content: 'Thiraseela.com Academy Portfolios :: ' + this.MetaName + '-' + this.MetaTagline},
                { property: 'og:title', content: this.MetaName + ' - ' + this.MetaTagline + ' - Academy Portfolio :: Thiraseela.com'},
                { property: 'og:site_name', content: 'Thiraseela.com'},
                { property: 'og:description', content: 'Thiraseela.com Academy Portfolios :: ' + this.MetaName + '-' + this.MetaTagline},
                {property: 'og:type', content: 'profile'},
                {property: 'og:url', content: 'https://thiraseela.com/performers-detail/' + this.MetaSeqno + '/' + this.MetaName},
                {property: 'og:image', content: this.MetaUrl }    
            ]
        }
    },    
    data() {
      return {
        ShowSideCatg: false,
        RespArray: [],
        RecentData: [],
        componentKey: 0,
        MetaName: '',
        MetaTagline: '',
        MetaSeqno: '',
        MetaUrl: '',        
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtlAcademy(to.params.AcademyId);
      this.componentKey=this.$route.params.AcademyId;
    }
  },         
    mounted()
    {
      this.ReadDtlAcademy(this.$route.params.AcademyId);
      this.RecentAcademy();
    },
    methods: {
      ReadDtlAcademy(RecToFetch){  
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadDtlAcademy',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.DtlAcademy;
        this.ShowSideCatg=true;
        const FirstObject = resp.data.DtlAcademy[0];
        this.MetaName = FirstObject.academy_name;
        this.MetaTagline = FirstObject.academy_type;
        this.MetaSeqno = FirstObject.seq_no;
        this.MetaUrl = FirstObject.academy_photo;   
        // console.log('MetaName',this.MetaName)
        // console.log('MetaTagline',this.MetaTagline)
        // console.log('MetaSeqno',this.MetaSeqno)
        // console.log('MetaUrl',this.MetaUrl)             
      })
      },     
        RecentAcademy(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentAcademy')
        .then((resp)=>{
        this.RecentData=resp.data.RecentAcademy;
      })
      },         
    },
    computed: {      
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    float: center;
    width: 300px;
    padding: 10px 10px 10px 10px;
    height: auto;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: auto;
  /* overflow: hidden; */

}
.profile-card-5 .card-img-block img{
    float: left;
    width: 300px;
    margin-top:5px;
    padding: 10px 20px 20px 10px;
    max-height: 400px;
    border-radius: 50px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify; 
  /* display: inline-block; */
}
.tab-desc-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 450px;
  overflow: scroll;  
  /* display: inline-block; */
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}

.contact-card {
    margin-top:25px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.contact-card h5{
    margin-top:20px;
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.contact-card p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.contact-card .social-links a {
    font-size: 25px;
    display: inline-block;
    /* background: #1e4356; */
    color: #e75480;
    line-height: 1;
    padding: 12px 0;
    margin-right: 4px;
    margin-bottom:30px;
    border-radius: 50%;
    border: 2px solid #008cff;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}

.contact-card .social-links a:hover {
    background: #e75480;
    color: #fff;
    text-decoration: none;
}
</style>
